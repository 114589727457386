<template>
  <div id="data-list-thumb-view" class="data-list-container">
      <order-list-table />
  </div>
</template>

<script>

import OrderListTable from "@/components/order/OrderListTable.vue"
// Cell Renderer
export default {
  name:"order-list",
  components: {
    OrderListTable,
  },
  // mounted() {
  //   this.$store.commit('TOGGLE_REDUCE_BUTTON', true)
  // },
}
</script>
